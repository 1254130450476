<template>
    <div class="">

        <!-- Page Banner Start-->
        <PageBanner
            :pgLoading="pgLoading"
            :background="(page.image) ? page.image.url : ''"
            :currentPage="($i18n.locale == 'ar') ? page.title_ar : page.title_en">
        </PageBanner>
        <!-- Page Banner End -->


        <!-- New-Card Start -->
        <section id="news" class="news-section-details padding_bottom_half padding_top">
          <div class="container">

            <div v-if="pgLoading" 
                class="row"
                :class="($i18n.locale == 'ar') ? 'rtl' : ''">
                
                <div v-for="(row, index) in 6"
                  :key="index"
                  class="col-md-4 col-sm-6 col-xs-12 heading_space">

                    <div class="sim-lar-p">
                        <div class="image bottom20">
                            <div class="webkit-animation webkit-100per-235"></div>
                        </div>
                        <div class="sim-lar-text">
                            <h3 class="bottom10 webkit-animation webkit-50per-50"></h3>
                            <p><span class="webkit-animation webkit-25per-35"></span></p>
                            <p class="bottom20 webkit-animation webkit-100per-100 mb-5"></p>
                            <p class="btn-more webkit-animation webkit-50per-50"></p>
                        </div>
                    </div>

                </div>
            </div>
            
            <div v-if="!pgLoading" 
                class="row"
                :class="($i18n.locale == 'ar') ? 'rtl' : ''">

                <div v-for="(item, index) in items"
                    :key="index"
                    class="col-md-4 col-sm-6 col-xs-12 heading_space">
                    <NewsCard :item="item"></NewsCard>
                </div>
            </div>


          </div>
        </section>
        <!-- New-Card End -->


        <LoadMoreButton
            :pgLoading="pgLoading"
            :hasMore="hasMore"
            :btnLoading="btnLoading"
            
            v-on:loadMoreClicked="handleLoadMore">
        </LoadMoreButton>


    </div>
</template>


<script>
export default {
    name: 'List',
    components: {
        PageBanner: () => import('@/components/PageBanner.vue'),
        NewsCard: () => import('@/components/NewsCard.vue'),
        LoadMoreButton: () => import('@/components/LoadMoreButton.vue'),
    },
    data(){
        return {
            pgLoading: true,
            hasMore: false,
            btnLoading: false,
            btnClicked: false,
            pageNo: 1,

            page: '',
            setting: '',
            items: []
        }
    },
    metaInfo() {
        return {
            title: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title',
            meta: [
                { 
                    name: 'description', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_description : this.page.meta.en.meta_description : 'meta_description'
                },
                { 
                    name: 'keywords', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_keywords : this.page.meta.en.meta_keywords : 'meta_keywords'
                },
                { 
                    name: 'title', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title'
                }  
            ]
        }
    },
    mounted() {},
    created() {

        this.fetchData(true);
    },
    methods: {
          
        fetchData(loading=false) {
            if(loading) {
                this.pgLoading = true;
            }
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            let vm = this;
            const options = {
                url: window.baseURL+'/news',
                method: 'GET',
                data: {},
                params: {
                    page: this.pageNo,
                    paginate: 3,
                    keyword: this.$route.query.keyword,
                    category: this.$route.query.category
                }
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.btnLoading = false;
                this.page = res.data.page;
                  
                if(this.btnClicked) {
                    this.items = vm.items.concat(res.data.items);
                } else {
                    this.items = res.data.items;
                }

                this.btnClicked = false;

                if(res.data.paginate.next_page > 0) {
                    this.hasMore = true;
                } else {
                    this.hasMore = false;
                }

            })
            .catch(() => {})
            .finally(() => {});
        },

        handleLoadMore() {
            this.btnClicked = true;
            this.btnLoading = true;
            this.pageNo++;
            this.fetchData();
        },

          
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
